<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 계정관리</h2>
    </div>
    <div class="search-area mt-s">
      <div class="search-item">
        <t-rich-select
          :options="searchFieldList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="name"
          valueAttribute="id"
          :hideSearchBox="true"
          v-model="searchField"
        >
        </t-rich-select>

        <div class="form-group">
          <div class="sh-in-box">
            <i class="icon-img ic-main-search"></i>
            <input class="element-input w-300" type="text" v-model="searchValue" placeholder="검색어 입력">
          </div>
          <button type="button" class="element-btn bg-blue w-150" @click="searchManagerList()">검색</button>
        </div>
      </div>
      <div class="btn-item">
        <button type="button" class="element-btn bg-blue" @click="registManager()"><i class="icon-img ic-btn-add"></i>신규 등록</button>
        <button type="button" class="element-btn bg-purple" @click="displaySMSSend()"><i class="icon-img ic-btn-sms"></i>SMS 발송</button>
      </div>
    </div>
    
    <div class="table-head mt-xs">
      <div class="total-item">
        <span class="total">Total {{totalCount}}</span>
      </div>
      <div class="btn-item">
        <button type="button" class="btn-table-head" @click="deleteManagerList()">
          <span class="btn-txt">선택 삭제</span>
          <i class="icon-img ic-list-select-delete"></i>
        </button>
      </div>
    </div>
    
    <div class="table-a">
      <table>
        <colgroup>
          <col style="width:60px">
          <col style="width:80px">
          <col style="width:auto">
          <col style="width:180px">
          <col style="width:200px">
          <col style="width:200px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:120px">
          <col style="width:120px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">
            <div class="element-checkbox checkbox-text">
              <input type="checkbox" id="chk-all" class="checkbox-input" v-model="isCheckAll" @click="checkAll($event.target.checked)"><label for="chk-all" class="checkbox-label">전체</label>
            </div>
          </th>
          <th scope="col">No.</th>
          <th scope="col">아이디</th>
          <th scope="col">이름</th>
          <th scope="col">핸드폰번호</th>
          <th scope="col">이메일</th>
          <th scope="col">등록일</th>
          <th scope="col">권한유형</th>
          <th scope="col">수정</th>
          <th scope="col">패스워드</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(mItem, idx) in managerList" :key="idx">
          <td>
            <div class="element-checkbox">
              <input type="checkbox" :id="'check_' + idx" class="checkbox-input" @change="checkItem($event)" v-model="mItem.selector"><label :for="'check_' + idx" class="checkbox-label">선택</label>
            </div>
          </td>
          <td>{{ totalCount - idx - (limit * ( currentPage - 1 )) }}</td>
          <td>{{decryptString(mItem.managerID)}}</td>
          <td>{{decryptString(mItem.managerName)}}</td>
          <td>{{decryptString(mItem.managerPhone) | phoneNumber}}</td>
          <td>{{decryptString(mItem.managerEmail)}}</td>
          <td>{{mItem.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</td>
          <td>{{managerRole[mItem.managerRole]}}</td>
          <td><button type="button" class="btn-modify" @click="updateManager(mItem.managerGuid)"><i class="icon-img ic-txt-list-edit"></i><span class="for-a11y">수정하기</span></button></td>
          <td><a @click="chgManagerPasswd(mItem.managerGuid)" class="link-a cursor-pointer">비밀번호 변경</a></td>
        </tr>
        </tbody>
      </table>
    </div>
    
    <paging-component :totalCount="totalCount" :limit="limit" :currentPage="currentPage" @chgCurrentPage="chgCurrentPage"></paging-component>

    <!-- 패스워드 변경 다이얼로그 START -->
    <div class="module-dimmed" v-if="showPasswordDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">패스워드 변경</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item">
                <span class="form-label">아이디</span>
                <div class="form-inp">{{managerPWID}}</div>
              </div>
              <div class="form-item">
                <span class="form-label">
                  <label for="pw-01">패스워드</label>
                </span>
                <div class="form-inp">
                  <input type="password" v-model="managerPassword" placeholder="패스워드" id="pw-01" class="element-input size-lg">
                </div>
              </div>
              <div class="form-item">
                <span class="form-label">
                  <label for="pw-02">패스워드 확인</label>
                </span>
                <div class="form-inp">
                  <input type="password" v-model="managerPasswordCheck" placeholder="패스워드 다시 입력" id="pw-02" class="element-input size-lg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray size-lg" @click="closePasswdDialog()">닫기</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="chgManagerPasswdAction()" :disabled="isChangePassword()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closePasswdDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 패스워드 변경 다이얼로그 END -->


    <!-- SMS 전송 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSMSSendDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">SMS발송</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item mt-0">
                <span class="form-label w-115">수신번호</span>
                <div class="form-inp">
                  <div class="phone-number-group" :class="{'opened' : isShowPhoneNumber}" v-if="sendSMSList.length > 0">
                    <div class="phone-number-item">
                      <span class="phone-number" v-for="(sItem, idx) in sendSMSList" :key="idx">{{decryptString(sItem) | phoneNumber}}</span>
                    </div>
                    <span class="phone-number besides-info" v-if="!isShowPhoneNumber && sendSMSList.length > 2">외 {{sendSMSList.length - 2}}명</span>
                    <button type="button" class="btn-toggle" @click="isShowPhoneNumber = !isShowPhoneNumber" v-if="sendSMSList.length > 2">
                      <i class="icon-img arrow-dw-big"></i>
                      <span class="for-a11y">더보기</span>
                    </button>
                  </div>
                  <div v-else>등록된 수신번호가 없습니다.</div>
                  <div class="phone-number-add"><button type="button" class="btn-add-phone" @click="displaySMSList()">+ 추가</button></div>
                </div>
              </div>
              <div class="form-item">
                <span class="form-label w-115">
                  <label for="pw-02">내용</label>
                </span>
                <div class="form-inp">
                  <p class="text-xs text-gray-500 mb-2">남은 글자 수 : {{smsTextLength - sendSMSContent.length}}</p>
                  <textarea class="element-textarea" v-model="sendSMSContent" @input="checkSMSLength()"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeSMSSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="sendSMSSubmit()">발송</button>
        </div>
        <button type="button" class="btn-close" @click="closeSMSSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- SMS 전송 다이얼로그 END -->

    <!-- SMS 목록 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSMSListDialog">
      <div class="module-dialog dialog-xl">
        <div class="dialog-header">
          <h2 class="dialog-title">관리자 목록</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="table-a">
              <table>
                <colgroup>
                  <col style="width:50px">
                  <col style="width:180px">
                  <col style="width:180px">
                  <col style="width:180px">
                  <col style="width:120px">
                  <col style="width:180px">
                  <col style="width:180px">
                  <col style="width:60px">
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">아이디</th>
                  <th scope="col">이름</th>
                  <th scope="col">소속</th>
                  <th scope="col">핸드폰번호</th>
                  <th scope="col">등록일</th>
                  <th scope="col"><DropdownRole @checkSMSRole="checkSMSRole"></DropdownRole></th>
                  <th scope="col">
                    <div class="element-checkbox checkbox-text">
                      <input type="checkbox" id="chk-all" class="checkbox-input" v-model="isCheckAllSMS" @click="checkAllSMS($event.target.checked)"><label for="chk-all" class="checkbox-label">선택</label>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(tItem, idx) in memberSMSList" :key="idx">
                  <td>{{memberSMSList.length - idx}}</td>
                  <td>{{tItem.userID}}</td>
                  <td>{{decryptString(tItem.userName)}}</td>
                  <td>{{tItem.companyName}}</td>
                  <td>{{decryptString(tItem.userPhone) | phoneNumber}}</td>
                  <td>{{tItem.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</td>
                  <td>{{managerRole[tItem.userRole]}}</td>
                  <td>
                    <div class="element-checkbox">
                      <input type="checkbox" :id="'chk' + idx" class="checkbox-input" @change="checkItemSMS($event)" v-model="tItem.selector">
                      <label :for="'chk' + idx" class="checkbox-label">선택</label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="form-group mt-sm">
              <div class="form-item">
                <span class="form-label">
                  <label for="pw-02">수신 번호 추가</label>
                </span>
                <div class="form-inp">
                  <input type="text" class="element-input size-lg" v-model="addSMS" @keyup="validateSMSNumber()" placeholder="'-' 없이 번호 입력, 쉼표로 구분해주세요">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeSMSList()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="saveSMSList()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeSMSList()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- SMS 목록 다이얼로그 END -->

    <!-- 삭제 다이얼로그 START -->
    <div class="module-dimmed" v-if="showDeleteDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="text-lg font-bold text-red-700 text-center">다시 한번 확인해 주세요.</p>
            <div class="red-box mt-s">
              <p class="title">체크사항</p>
              <p class="desc-a">
                1. 삭제된 내용은 복구 할 수 없습니다.<br>
              </p>
            </div>
            <p class="confirm-text">관련된 내용이 삭제됩니다.<br>삭제하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="deleteManagerListCancel()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="deleteManagerListAction()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="deleteManagerListCancel()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 삭제 다이얼로그 END -->

    <!-- 발송 다이얼로그 START -->
    <div class="module-dimmed" v-if="showSMSSendConfirmDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">문자를 발송 합니다.<br>발송하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeSMSSendConfirmDialog()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="submitSMSSendConfirmDialog()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeSMSSendConfirmDialog()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 삭제 다이얼로그 END -->

  </div>
</template>

<script>
import PagingComponent from "@/components/elements/paging"
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { managerRole } from "@/references/config"
import DropdownRole from "@/components/elements/DropdownRole"

import { encryptString, decryptString } from '@/utils/config'

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getManagerList()
  },
  components: {
    PagingComponent,
    DropdownRole
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',
      managerRole,

      searchFieldList: [
        {id: "userID", name: "아이디" },
        {id: "userName", name: "이름" },
        {id: "userPhone", name: "핸드폰번호" },
      ],
      searchField: 'userID',
      searchValue: '',

      limit: 15,
      currentPage: 1,
      totalCount: 0,
      managerList: [],

      selectedItem: [],
      isCheckAll: false,
      isCheckAllSMS: false,

      showPasswordDialog: false,
      managerPWID: '',
      managerPWGuid: '',
      managerPassword: '',
      managerPasswordCheck: '',

      showSMSSendDialog: false,
      isShowPhoneNumber: false,

      showSMSListDialog: false,
      showSMSSendConfirmDialog: false,

      sendSMSList: [],
      sendSMSContent: '',
      memberSMSList: [],
      checkedSMS: [],
      addSMS: '',
      smsTextLength: 50,

      showDeleteDialog: false,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    decryptString,
    checkAll (checked) {
      this.isCheckAll = checked;
      for (let i in this.managerList) {
        this.managerList[i].selector = this.isCheckAll;
      }
    },
    checkItem () {
      for (let i in this.managerList) {
        if(! this.managerList[i].selector) {
          this.isCheckAll = false;
          return;
        } else {
          this.isCheckAll = true;
        }
      }
    },

    checkAllSMS (checked) {
      this.isCheckAllSMS = checked;
      for (let i in this.memberSMSList) {
        this.memberSMSList[i].selector = this.isCheckAllSMS;
      }
    },
    checkItemSMS () {
      for (let i in this.memberSMSList) {
        if(! this.memberSMSList[i].selector) {
          this.isCheckAllSMS = false;
          return;
        } else {
          this.isCheckAllSMS = true;
        }
      }
    },
    checkSMSRole (type) {
      for (let i in this.memberSMSList) {
        if(type == this.memberSMSList[i].userRole) {
          this.memberSMSList[i].selector = true;
        }
      }
    },
    
    registManager () {
      this.$router.push({name: "ManagerRegist"})
    },
    updateManager (guid) {
      this.$router.push({name: "ManagerUpdate",query:{guid}})
    },
    async getManagerList() {
      let reqObj = {}
      reqObj.target = `/manager/list/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {
        "searchField": this.searchField,
        "searchValue": this.searchValue,
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        let tempList = result.msg.retData.content
        let addSelector = tempList.map(data => ({ ...data, selector: false}))
        this.managerList = [...addSelector]

        this.totalCount = result.msg.retData.totalElements
      }
    },
    chgCurrentPage (num) {
      this.currentPage = num
      this.getManagerList()
    },

    deleteManagerListCancel () {
      this.showDeleteDialog = false
    },

    deleteManagerList () {
      this.selectedItem = [];
      for (let i in this.managerList) {
        if(this.managerList[i].selector) {
          this.selectedItem.push({"managerGuid":this.managerList[i].managerGuid});
        }
      }

      if(this.selectedItem.length > 0) {
        this.showDeleteDialog = true;
      }
    },
    async deleteManagerListAction() {
      this.chgIsOverlay(true)

      let reqObj = {}
      reqObj.target = `/manager/deleteList`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = this.selectedItem
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'삭제 했습니다.'})
        
        this.showDeleteDialog = false
        this.getManagerList()
      }
      this.chgIsOverlay(false)
    },

    searchManagerList () {
      this.currentPage = 1
      this.getManagerList();
    },


    chgManagerPasswd (guid) {
      this.showPasswordDialog = true
      let managerInfo = this.managerList.find(x => x.managerGuid === guid)
      this.managerPWID = decryptString(managerInfo.managerID);
      this.managerPWGuid = guid;
    },
    async chgManagerPasswdAction () {
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+<>?])[A-Za-z\d~!@#$%^&*()_+<>?]{8,}$/;
      if(this.managerPassword == "" || !passwdReg.test(this.managerPassword)) {
        this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
        return;
      }

      let reqObj = {}
      reqObj.target = "/manager/updatePassword";
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "managerGuid": this.managerPWGuid,
        "managerID": encryptString(this.managerPWID),
        "managerPassword": encryptString(this.managerPassword)
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'변경 했습니다.'})

        this.managerPWID = '';
        this.managerPassword = '';
        this.managerPasswordCheck = '';
        this.showPasswordDialog = false
      }
      else {
        this.chgIsAlert({status:true,string:'변경 실패 했습니다.'})
      }
    },
    closePasswdDialog () {
      this.managerPWID = '';
      this.managerPassword = '';
      this.managerPasswordCheck = '';
      this.showPasswordDialog = false
    },
    isChangePassword () {
      let retVal = true;
      if(this.managerPassword !== "" && this.managerPassword === this.managerPasswordCheck) retVal = false;
      return retVal;
    },


    displaySMSSend () {
      this.checkedSMS = []
      this.sendSMSList = []
      this.addSMS = ''
      this.sendSMSContent = ''
      this.showSMSSendDialog = true
    },
    displaySMSList () {
      this.isCheckAllSMS = false
      this.checkedSMS = []
      this.showSMSListDialog = true
      this.showSMSSendDialog = false
      this.getCompanyMemberList()
    },
    closeSMSSend () {
      this.showSMSSendDialog = false
    },
    closeSMSList() {
      this.showSMSListDialog = false
      this.showSMSSendDialog = true
    },
    saveSMSList () {
      for(let i=0; i<this.memberSMSList.length; i++) {
        if(this.memberSMSList[i].selector) this.checkedSMS.push(this.memberSMSList[i].userPhone)
      }
      this.sendSMSList = [...this.checkedSMS, ...this.addSMS.split(',')].filter((e) => {return e})
      this.showSMSListDialog = false
      this.showSMSSendDialog = true
    },
    validateSMSNumber () {
      this.addSMS = this.addSMS.replace(/[^0-9|,]/g, '');
    },
    checkSMSLength () {
      if(this.sendSMSContent.length >= this.smsTextLength) this.sendSMSContent = this.sendSMSContent.substring(0, this.smsTextLength)
    },
    sendSMSSubmit () {
      if(this.sendSMSList.length > 0) {
        this.showSMSSendConfirmDialog = true
      }
    },
    closeSMSSendConfirmDialog () {
      this.showSMSSendConfirmDialog = false
    },
    submitSMSSendConfirmDialog () {
      this.showSMSSendConfirmDialog = false
      this.sendSMSAction()
    },

    async sendSMSAction () {
      let reqObj = {}
      reqObj.target = `/sms/sendadmin`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "smsMessage": this.sendSMSContent,
        "smsNumberList": this.sendSMSList
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'전송했습니다.'})
        this.showSMSSendDialog = false
      }
    },

    async getCompanyMemberList () {
      let reqObj = {}
      reqObj.target = `/admin/listmember/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        let tempList = result.msg.retData
        let addSelector = tempList.map(data => ({ ...data, selector: false}))
        this.memberSMSList = [...addSelector]
      }
    },
  }
  
}
</script>
