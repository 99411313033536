<template lang="">
  <div class="flex justify-center items-center relative ml-5 mr-2" v-click-outside="closeDropdown">
    <button type="button" class="util-item" @click="isOpen = !isOpen">
      <i class="ic-top-navi-users"></i>
      <span class="text-sm font-black text-blue-600 mx-2">권한유형</span>
    </button>
    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    
    <div v-if="isOpen" class="absolute top-10 right-1 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl min-w-max px-5" style="z-index:10000">
      <p @click="checkSMSRole('admin')" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer border-b border-gray-100 border-solid">관리자</p>
      <p @click="checkSMSRole('member')" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer border-b border-gray-100 border-solid">운영자</p>
      <p @click="checkSMSRole('agent')" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer">현장관리자</p>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapGetters } from "vuex";

import { managerRole } from '@/references/config'

export default {
  props: {
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid","getCurrentUser"])
  },
  data () {
    return {
      isOpen: false,
      managerRole
    }
  },
  methods: {
    ...mapActions(["userLogoutAction"]),
    closeDropdown () {
      this.isOpen = false
    },
    checkSMSRole (type) {
      this.$emit('checkSMSRole', type)
      this.isOpen = false
    }
  }
}
</script>
<style lang="">
</style>